import React from 'react';
import formatCurrency from 'Common/Utils/formatCurrency';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';

import DocumentList from 'Common/UI/UploadedDocuments/DocumentList';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import Paragraph from 'Common/UI/Text/Paragraph';
import isFileObject from 'Common/Utils/isFileObject';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';

export const yourSuperannuationLabel = 'Your Superannuation';

// eslint-disable-next-line import/prefer-default-export
export const yourSuperannuationQuestions: QuestionAny[] = [
  {
    name: 'intro',
    label: () => (
      <>
        We&#39;re going to ask you a few questions to help you value your
        superannuation. Before you get started, make sure you have the amount of
        your superannuation balance.
      </>
    ),
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'intro2',
    label:
      'Before you get started, you should know that we can’t split superannuation',
    content: () => (
      <>
        <Paragraph large>
          Working out a fair division of your money and property can involve
          splitting superannuation when there is a large difference in your
          superannuation balances.
        </Paragraph>
        <Paragraph large>
          We will let you know if you have a large difference between your
          superannuation balances when each of you complete this section.
        </Paragraph>
        <Paragraph large>
          For more information see our{' '}
          <ReferralLink href="https://www.amica.gov.au/money-and-property.html">
            Money and Property page
          </ReferralLink>
        </Paragraph>
      </>
    ),
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 1,
    submitButtonLabel: 'I understand',
  },
  {
    name: 'intro3',
    label:
      'Selecting ‘other’ as your Superannuation type means you won’t be able to turn this into a consent order',
    content: () => (
      <>
        <Paragraph large>
          {`You can only make consent orders using amica for certain 
          Superannuation types. You have have chosen ‘other. We recommend 
          you seek legal help or visit our Money and Property page.`}
        </Paragraph>
        <Paragraph large>
          For more information see our{' '}
          <ReferralLink href="https://www.amica.gov.au/money-and-property.html">
            Money and Property page
          </ReferralLink>
        </Paragraph>
      </>
    ),
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 2,
    submitButtonLabel: 'I understand',
  },
  {
    name: 'superannuationYesNo',
    label: 'Do you have superannuation?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 3,
  },
  {
    name: 'superannuationName',
    label: "What's the name of your fund?",
    hint: '',
    content: (
      <Paragraph>
        Please enter the name of the fund exactly as it appears on your
        superannuation statement.
      </Paragraph>
    ),
    type: 'text',
    defaultValue: '',
    visible: answer => !!answer.superannuationYesNo,
    index: 4,
  },
  {
    name: 'superannuationType',
    label: 'What type of superannuation fund do you have?',
    hint: '',
    type: 'select',
    options: () => [
      { label: 'Accumulation interest', value: 'Accumulation interest' },
      {
        label: 'Partially vested accumulation interest',
        value: 'Partially invested accumulation interest',
      },
      { label: 'Defined benefit interest', value: 'Defined benefit interest' },
      { label: 'Other', value: 'Other' },
    ],
    visible: answer => !!answer.superannuationYesNo,
    defaultValue: '',
    index: 5,
  },
  {
    name: 'superannuationTypeOtherInfo',
    label:
      'Selecting ‘other’ as your Superannuation type means you won’t be able to turn this into a consent order',
    content: (
      <Paragraph large>
        {`You can only make consent orders using amica for certain 
          Superannuation types. You have have chosen ‘other. We recommend 
          you seek legal help or visit our Money and Property page.`}
      </Paragraph>
    ),
    hint: '',
    type: 'info',
    visible: answer => answer.superannuationType === 'Other',
    defaultValue: '',
    submitButtonLabel: 'I understand',
    index: 6,
  },
  {
    name: 'superannuationValue',
    label: 'What is the value of your fund?',
    hint: '',
    type: 'currency',
    visible: answer => !!answer.superannuationYesNo,
    defaultValue: '',
    index: 7,
  },
  {
    name: 'superannuationProof',
    label: 'Would you like to upload supporting documents?',
    hint: '',
    type: 'docupload',
    visible: answer => !!answer.superannuationYesNo,
    defaultValue: '',
    preSaveResponses: (value: any) =>
      value
        .filter((v: any) => v)
        .map((v: Record<string, unknown>) => {
          if (v instanceof File) {
            return v.imagePath;
          }

          return v;
        }),
    optional: true,
    index: 8,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 9,
  },
];

export const yourSuperannuationStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.CardIndex || matter.self.party;
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'superannuationName',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    yourSuperannuationQuestions,
    data,
    hideSensitive
  );

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  // Data
  const {
    superannuationYesNo: hasSuper,
    superannuationType: superType,
    superannuationValue,
    superannuationProof,
  } = data;

  const superTypeDescription =
    superType === 'Other'
      ? 'other superannuation type'
      : `${superType} superannuation account`;

  // Currency format
  const superValue = formatCurrency(parseFloat(superannuationValue as string));

  // Sensitive Data that should not appear on LSC email data collection
  const superName = updated('superannuationName', redact('superannuationName'));

  if (plainText) {
    return hasSuper
      ? `${firstName} has a ${superTypeDescription} with ${superName} valued at ${superValue}.`
      : `${firstName} does not have any superannuation.`;
  }

  const highlightedText = hasSuper
    ? highlight`${firstName} has a ${superTypeDescription} with ${superName} valued at ${superValue}.`
    : highlight`${firstName} does not have any superannuation.`;

  let files = null;

  // Create a document list if they have been uploaded
  if (
    superannuationProof &&
    superannuationProof.length &&
    !isFileObject(superannuationProof)
  ) {
    files = <DocumentList documents={superannuationProof} />;
  }

  return (
    <>
      {highlightedText}
      {files}
    </>
  );
};
