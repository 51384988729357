import React from 'react';
import formatCurrency from 'Common/Utils/formatCurrency';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';

import DocumentList from 'Common/UI/UploadedDocuments/DocumentList';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import isFileObject from 'Common/Utils/isFileObject';
import { Party, PARTY_A, PARTY_B } from 'Common/constants';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Matter from 'Common/Data/Types/matter';

export const yourSuperannuationLabel = 'Your Superannuation';

// eslint-disable-next-line import/prefer-default-export
export const yourSuperannuationQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about your superannuation.
      </H1Conversational>
    ),
    content: () => (
      <>
        <Paragraph>
          These questions are important because they will help us to understand
          the value of your superannuation.
        </Paragraph>
        <Paragraph>
          Before you start, make sure you have your latest superannuation
          statement handy so you can check what type of superannuation fund you
          have and what the current balance is.
        </Paragraph>
      </>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'carousel',
    type: 'carousel',
    index: 1,
    slides: [
      {
        title: 'Splitting your Superannuation',
        content: (
          <>
            <Callout>
              First, there are some things we need to tell you about
              superannuation using amica.
            </Callout>
            <Callout>
              Superannuation is considered an asset for division of money and
              property after separation. It is treated differently because it is
              an asset that is held on trust. To get more info about
              superannuation see our{' '}
              <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
                Money and Property
              </ReferralLink>{' '}
              page, or contact your local free{' '}
              <ReferralLink href="https://www.amica.gov.au/help-and-support">
                Legal Help Line
              </ReferralLink>
              .
            </Callout>
            <Callout>
              When there is a difference in your superannuation balances, you
              have two options to work out a fair division of your money and
              property in amica. You can both agree to each keep your own
              superannuation or one of you can divide your superannuation
              interests or entitlements. Dividing your superannuation involves
              giving some of your superannuation to your former partner. This is
              not a cash payment but a transfer of superannuation funds from one
              fund to the other, so it has different rules and a different legal
              process.
            </Callout>
          </>
        ),
        showHelpButton: true,
        helpButtonText: 'Why?',
        helpContent: (
          <>
            <H1Conversational>Why?</H1Conversational>
            <Paragraph large>
              We take into account the amount of superannuation that each of you
              has when we suggest what's fair. To get more info about
              super-splitting, contact your local free{' '}
              <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
                Legal Help Line
              </ReferralLink>
              .
            </Paragraph>
          </>
        ),
      },
      {
        title: 'Getting advice',
        content: (
          <Callout>
            If there is a big difference between your superannuation balances,
            you might need to get some advice before finalising your agreement.
          </Callout>
        ),
        showHelpButton: true,
        helpButtonText: 'Why?',
        helpContent: (
          <>
            <H1Conversational>Why?</H1Conversational>
            <Paragraph large>
              We do this so that you have the opportunity to get advice to make
              sure your agreement is fair before you finalise it. To get more
              info about superannuation differences when you separate, contact
              your local free{' '}
              <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
                Legal Help Line
              </ReferralLink>
              .
            </Paragraph>
          </>
        ),
      },
      {
        title: 'Type of super fund',
        content: (
          <>
            <Callout>
              There are many types of superannuation funds. If you want to
              create a property agreement or an application for consent orders
              using amica, you will be able to do this if your superannuation
              account is one of these types:
            </Callout>
            <Callout as="ul">
              <li>accumulation interest</li>
              <li>partially vested accumulation interest</li>
              <li>defined benefit interest</li>
            </Callout>
            <Callout>
              If you are planning to split your superannuation you can only
              choose accumulation type funds at the superannuation splitting
              stage on amica. If you are unsure what type of fund you have, you
              should check with your superannuation fund.
            </Callout>
          </>
        ),
        showHelpButton: true,
        helpButtonText: 'Why?',
        helpContent: (
          <>
            <H1Conversational>Why?</H1Conversational>
            <Paragraph large>
              We do this because some types of super funds need an independent
              valuation for consent orders, which amica doesn’t cover. To get
              more info about superannuation types see our{' '}
              <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
                Money and Property
              </ReferralLink>
              . page, or contact your local free{' '}
              <ReferralLink href="https://amica.gov.au/help-and-support">
                Legal Help Line
              </ReferralLink>
              .
            </Paragraph>
          </>
        ),
      },
      {
        title: 'Type of super fund',
        content: (
          <Callout>
            If you have more than one super account, add all of your accounts.
          </Callout>
        ),
        showHelpButton: true,
        helpButtonText: 'Why?',
        helpContent: (
          <>
            <H1Conversational>Why?</H1Conversational>
            <Paragraph large>
              We ask for this because you need to include all of your super
              balances as part of your full and frank disclosure requirements.
            </Paragraph>
            <Paragraph large>
              For more information on superannuation, see our{' '}
              <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
                Money and Property
              </ReferralLink>
              . page, or contact your local free{' '}
              <ReferralLink href="https://amica.gov.au/help-and-support">
                Legal Help Line
              </ReferralLink>
              .
            </Paragraph>
          </>
        ),
      },
    ],
  },
  {
    name: 'superannuationName',
    label: "What's the name of your fund?",
    hint: '',
    content: (
      <Paragraph>
        Please enter the name of the fund exactly as it appears on your
        superannuation statement.
      </Paragraph>
    ),
    type: 'text',
    defaultValue: '',
    index: 2,
  },
  {
    name: 'superannuationType',
    label: 'What type of superannuation fund do you have?',
    hint: '',
    type: 'select',
    options: () => [
      { label: 'Accumulation interest', value: 'Accumulation interest' },
      {
        label: 'Partially vested accumulation interest',
        value: 'Partially invested accumulation interest',
      },
      { label: 'Defined benefit interest', value: 'Defined benefit interest' },
      { label: 'Other', value: 'Other' },
    ],
    defaultValue: '',
    index: 3,
    checkShowDrawerOnInput: (value: any) => {
      if (value === 'Other') {
        return {
          showDrawer: true,
          drawerContent: (
            <>
              <H1Conversational>
                Selecting ‘other’ as your super type means you won’t be able to
                turn this into a consent order
              </H1Conversational>
              <Paragraph>
                You have chosen ‘other'. You will be able to make a property
                agreement on amica, but you won't be able to make consent order
                documents on amica.
              </Paragraph>

              <Paragraph>
                <strong>Why?</strong>
              </Paragraph>
              <Paragraph>
                Because you can only make consent order documents on amica if
                your superannuation is one of these types:
              </Paragraph>

              <ul>
                <li>Accumulation interest</li>
                <li>Partially vested accumulation interest</li>
                <li>Defined benefit interest</li>
              </ul>
              <Paragraph>
                For more information on superannuation types see our{' '}
                <ReferralLink href="https://amica.gov.au/how-it-works/money-and-property">
                  Money and Property
                </ReferralLink>
                . page, or contact your local free{' '}
                <ReferralLink href="https://amica.gov.au/help-and-support">
                  Legal Help Line
                </ReferralLink>
                .
              </Paragraph>
            </>
          ),
          closeButton: 'Got it',
        };
      }

      return {
        showDrawer: false,
      };
    },
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>

          <Paragraph large>
            There are different types superannuation accounts. For more
            information see our{' '}
            <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
              Money and Property
            </ReferralLink>{' '}
            page.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'superannuationValue',
    label: 'What is the current value of your superannuation?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 4,
  },
  {
    name: 'superannuationProof',
    label: 'Would you like to upload supporting documents?',
    hint: '',
    type: 'docupload',
    defaultValue: '',
    preSaveResponses: (value: any) =>
      value
        .filter((v: any) => v)
        .map((v: Record<string, unknown>) => {
          if (v instanceof File) {
            return v.imagePath;
          }

          return v;
        }),
    optional: true,
    index: 5,
    help: {
      label: 'What kind of documents should I upload?',
      content: (
        <>
          <H1Conversational>
            What kind of documents should I upload?
          </H1Conversational>

          <Paragraph large>
            You can upload your latest superannuation account statement. For
            more information see our{' '}
            <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
              Money and Property
            </ReferralLink>{' '}
            page.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 6,
  },
];

export const yourSuperannuationStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const party: Party = data.creator || matter.self.party;
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'superannuationName',
    'superTypeDescription',
    'superValue',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    yourSuperannuationQuestions,
    data,
    hideSensitive
  );

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  // Data
  const {
    superannuationType: superType,
    superannuationValue,
    superannuationProof,
  } = data;

  const superTypeDescription =
    superType === 'Other'
      ? 'other superannuation type'
      : `${superType} superannuation account`;

  // Currency format
  const superValue = formatCurrency(parseFloat(superannuationValue as string));

  // Sensitive Data that should not appear on LSC email data collection
  const superName = updated('superannuationName', redact('superannuationName'));

  if (plainText) {
    return `${firstName} has a ${superTypeDescription} with ${superName} valued at ${superValue}.`;
  }

  const highlightedText = highlight`${firstName} has an ${updated(
    'superTypeDescription',
    redact('superTypeDescription', superTypeDescription)
  )} with ${superName} valued at ${updated(
    'superValue',
    redact('superValue', superValue)
  )}.`;

  let files = null;

  // Create a document list if they have been uploaded
  if (
    superannuationProof &&
    superannuationProof.length &&
    !isFileObject(superannuationProof)
  ) {
    files = <DocumentList documents={superannuationProof} />;
  }

  return (
    <>
      {highlightedText}
      {files}
    </>
  );
};

export const noSuperannuationQuestions: QuestionAny[] = [
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 0,
  },
];

export const noSuperannuationStatement = ({
  matter,
  data,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party: Party = data.creator || matter.self.party;
  const highlight = getHighlightStatementValuesFunction(plainText);

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  const statement = `${firstName} does not have any superannuation accounts.`;

  if (plainText) {
    return statement;
  }

  const highlightedText = highlight`${statement}`;

  return <>{highlightedText}</>;
};
